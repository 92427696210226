<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="cancelReturnShipment" />
      </template>

      <template v-slot:title>
        <h2>{{ $t("dashboard.receive") }}</h2>
        <h1>{{ $t("packetReturnShipment.return") }}</h1>
      </template>
    </AppHeader>

    <main>
      <section class="e-shop">
        <alert-circle-icon class="alert-circle-icon" />
        <h3>{{ $t("packetReturnShipment.verify") }}</h3>
      </section>
      <section class="confirm container">
        <h2>
          {{ $t("packetReturnShipment.confirm") }}
        </h2>
        <h3>{{ eshopName }}</h3>
      </section>
      <Errors :errors="errors" />
      <section class="customer container">
        <form @submit.prevent="onSubmit">
          <label>
            {{ $t("packetReturnShipment.customer")
            }}<span class="mandatory">*</span>
            <input
              type="text"
              ref="customerForm"
              :value="customerName"
              @input="e => (customerName = e.target.value.trim())"
            />
          </label>
          <button :class="['btn btn-primary', submitButtonClass]">
            {{ $t("packetReturnShipment.accept-packet") }}
          </button>
        </form>
        <a href="#" class="a-black" @click.prevent="cancelReturnShipment">{{
          $t("packetReturnShipment.cancel")
        }}</a>
      </section>
    </main>
    <OverlayPrint v-if="showPrintOverlay" />

    <div class="spinner-wrapper">
      <Spinner v-if="loading" static />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Errors from "@/components/Errors";
import { delay } from "lodash";
import { MOBILE_TYPES } from "@/util/mobile.type";
import AppHeader from "@/components/AppHeader";
import AlertCircleIcon from "vue-material-design-icons/AlertCircle";
import Close from "vue-material-design-icons/Close.vue";
import OverlayPrint from "@/components/OverlayPrint";
import { getParams } from "@/mixins/platform-params.js";
import Spinner from "@/components/Spinner";

const REPEAT_CHECK_STATUS = 1000;

export default {
  name: "PacketReturnShipment",
  mixins: [getParams],
  components: {
    Errors,
    AppHeader,
    AlertCircleIcon,
    Close,
    OverlayPrint,
    Spinner
  },
  data() {
    return {
      code: this.$route.params.code,
      customerName: null,
      showPrintOverlay: false,
      statusPending: false
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    this.resetPacketAndPaymentState();
    next();
  },

  beforeDestroy() {
    this.stopRefresh();
  },

  async mounted() {
    delay(() => {
      this.$refs.customerForm.focus();
    }, 500);
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("packet", ["eshopName", "packetBarcode"]),

    submitButtonClass() {
      return !this.customerName || this.statusPending
        ? "btn-grey disable"
        : "btn-green";
    }
  },

  methods: {
    ...mapActions(["resetPacketAndPaymentState"]),
    ...mapActions("packet", [
      "createReturnShipment",
      "getReturnShipmentDetail"
    ]),
    ...mapMutations(["clearErrors", "setLoading"]),

    async onSubmit() {
      const response = await this.createReturnShipment({
        password: this.code,
        customer_name: this.customerName
      });
      if (!response) return;

      this.startRefreshingStatus();
    },

    startRefreshingStatus() {
      // We need constant loading until the return shipment is completed
      this.setLoading(true);
      this.statusPending = true;

      this.intervalId = setInterval(async () => {
        const finished = await this.getReturnShipmentDetail();

        finished && this.finish();
      }, REPEAT_CHECK_STATUS);
    },

    finish() {
      this.stopRefresh();

      if (!this.packetBarcode) return;

      this.showPrintOverlay = true;
    },

    cancelReturnShipment() {
      this.stopRefresh();

      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.closeDetail();
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.closeDetail.postMessage({});
        }
      } else {
        if (
          window.confirm(
            this.$t("packetReturnShipment.cancel-return-shipment-confirm")
          )
        ) {
          this.$router.replace({
            name: "packet-search",
            query: {
              platform: this.platform,
              device: this.device
            }
          });
        }
      }
    },

    stopRefresh() {
      clearInterval(this.intervalId);

      this.statusPending = false;
      this.setLoading(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.e-shop {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: $orange-red;
  color: $white;
  padding: 6px 21px;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1);

  .alert-circle-icon {
    margin-right: 8px;
    line-height: 0;
  }
}

.confirm {
  padding: 11px 41px 0;

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: $orange-red;
  }

  h2 {
    color: $light-grey;
  }
}

.customer {
  text-align: center;
  padding: 0 16px;

  form {
    width: 100%;

    label {
      display: inline-block;
      text-align: left;
      margin-top: 23px;
    }

    button {
      margin: 20px 0 27px;
    }
  }
}

.spinner-wrapper {
  position: relative;
}
</style>
